<template>
  <div class="mian" :style="MobileTerminal ? 'font-size:0.75rem;' : ''">
    <!-- pc端 -->
    <div class="head" v-if="!MobileTerminal">
      <div class="head_w">
        <div class="head_left">
          <img src="./assets/image/logo.png" alt="" />
          <div class="head_l_t">
            <div>货易链</div>
            <div>HUOYILIAN</div>
          </div>
        </div>
        <div class="head_right">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="首页" name="homePage"></el-tab-pane>
            <el-tab-pane label="公司简介" name="companyProfile"></el-tab-pane>
            <el-tab-pane label="联系我们" name="contactUs"></el-tab-pane>
          </el-tabs>
          <div class="head_r_xz">下载APP</div>
          <div class="head_r_xz_popup">
            <img src="./assets/images/hylappios156.png" alt="" />
          </div>
          <div class="head_r_login">
            <div class="head_r_btn" @click="goLogin">
              员工登录<i class="el-icon-top-right"></i>
            </div>
          </div>
          <div class="head_r_login">
            <div class="head_r_btn" @click="dialogFormVisible = true">
              免费试用<i class="el-icon-top-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="head1" v-if="MobileTerminal">
      <div class="head_w1">
        <div style="display: flex; padding-top:1rem">
          <img
            style="width:2.6rem; height:2.6rem"
            src="./assets/image/logo.png"
            alt=""
          />
          <div class="head_l_t">
            <div>货易链</div>
            <div>HUOYILIAN</div>
          </div>
        </div>
        <div class="head_r_login">
          <div
            class="head_r_btn"
            style="border-radius:1.6rem"
            @click="dialogFormVisible = true"
          >
            免费试用
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName" stretch @tab-click="handleClick">
        <el-tab-pane label="首页" name="homePage"></el-tab-pane>
        <el-tab-pane label="公司简介" name="companyProfile"></el-tab-pane>
        <el-tab-pane label="联系我们" name="contactUs"></el-tab-pane>
        <el-tab-pane label="下载APP" name="downloadApp"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 内容 -->
    <router-view v-on:Change="Change"></router-view>
    <!-- 回到顶部 -->
    <div class="fixed" v-if="!MobileTerminal">
      <div @click="toTop">
        <img src="./assets/image/Back-to-top.png" alt="" />
      </div>
    </div>
    <!-- 试用弹窗 -->
    <div v-if="dialogFormVisible">
      <div
        v-if="!MobileTerminal"
        class="Trial_pop-up_window"
        :style="Submitted == 2 ? 'background:#fff' : ''"
      >
        <h2 v-if="Submitted !== 2">请填写以下信息:</h2>
        <h2 v-if="Submitted == 2" style="height:6.25rem"></h2>
        <el-form
          v-if="Submitted == 0"
          :rules="rules1"
          ref="forms"
          label-position="top"
          :model="forms"
          hide-required-asterisk
        >
          <el-form-item label="联系人" prop="name">
            <el-input
              style="width:25rem; height:3.125rem"
              v-model="forms.name"
              placeholder="请输入联系人"
              autocomplete="off"
              maxlength="20"
              required
            />
          </el-form-item>
          <el-form-item label="联系方式" prop="telephone">
            <el-input
              style="width: 25rem; height:3.125rem"
              placeholder="请输入联系方式"
              v-model="forms.telephone"
              autocomplete="off"
              maxlength="20"
              required
            />
          </el-form-item>
        </el-form>
        <el-form
          v-if="Submitted == 0"
          :rules="rules1"
          ref="formss"
          :model="formss"
        >
          <el-form-item label="类型" prop="resource">
            <el-radio-group v-model="formss.resource">
              <el-radio label="个人"></el-radio>
              <el-radio label="企业"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-form
          v-if="Enterprise && Submitted == 0"
          :rules="rules2"
          ref="formsss"
          label-position="top"
          :model="formsss"
          style="display: inline-block"
          hide-required-asterisk
        >
          <el-form-item label="企业名称" prop="company">
            <el-input
              style="width:28rem; height:3.125rem"
              v-model="formsss.company"
              placeholder="请输入企业名称"
              autocomplete="off"
              maxlength="30"
              required
            />
          </el-form-item>
        </el-form>
        <div
          v-if="Submitted == 0"
          class="Trial_pop-up_window_btn1"
          :style="Application ? 'background: #ff383b;' : ''"
          @click="confirm('forms', 'formsss')"
        >
          确认提交
        </div>
        <el-form
          v-if="Submitted == 1"
          :rules="rules"
          ref="form"
          label-position="top"
          :model="form"
        >
          <el-form-item label="手机号码" prop="phone">
            <el-input
              style="width: 25rem; height:3.125rem"
              v-model="form.phone"
              placeholder="请输入手机号码"
              autocomplete="off"
              maxlength="11"
            />
          </el-form-item>
          <el-form-item label="图形验证码" prop="imgcode">
            <el-input
              style="width: 14rem; height:3.125rem"
              placeholder="请输入验证码"
              @input="getImgcode"
              v-model="form.imgcode"
              autocomplete="off"
              maxlength="4"
            />
            <div class="get-code" @click="refreshCode()">
              <s-identify :identifyCode="identifyCode"></s-identify>
            </div>
          </el-form-item>
          <el-form-item label="短信验证码" prop="smsCode">
            <el-input
              style="width:14rem; height:3.125rem"
              placeholder="请输入短信验证码"
              v-model="form.smsCode"
              autocomplete="off"
              maxlength="6"
            />
            <div
              :class="getcode ? 'get_code' : 'get_code1'"
              @click="sendCode()"
              v-if="!click"
            >
              {{ text }}
            </div>
            <div class="get_code1" v-if="click">{{ text }}</div>
          </el-form-item>
        </el-form>
        <div v-if="Submitted == 2" style="text-align: center">
          <i class="el-icon-success"></i>
          <span style="color: #333; font-size: 2rem; margin-top: 1.25rem"
            >信息已提交，我们将在5个工作日内与您联系</span
          >
        </div>
        <div
          v-if="Submitted == 2"
          class="Trial_pop-up_window_btn"
          style="background: #ff383b; margin: auto"
          @click="gohome"
        >
          返回首页
        </div>
        <div
          v-if="Submitted == 1"
          class="Trial_pop-up_window_btn"
          :style="Application ? 'background: #ff383b;' : ''"
          @click="submitForm('form')"
        >
          申请试用
        </div>
        <div
          @click="dialogFormVisible = false"
          style="
            position: absolute;
            top: 0.625rem;
            right: 0px;
            width: 2.5rem;
            height: 2.5rem;
          "
        >
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
      <div v-if="MobileTerminal" :class="putAway ? 'Trial_pop-up_window_1' : 'Trial_pop-up_window_1_1'">
        <el-form
          v-if="Submitted == 0"
          :rules="rules1"
          ref="forms"
          label-position="top"
          :model="forms"
          hide-required-asterisk
        >
          <el-form-item label="联系人" prop="name">
            <el-input
              v-model="forms.name"
              placeholder="请输入联系人"
              autocomplete="off"
              maxlength="20"
              required
            />
          </el-form-item>
          <el-form-item label="联系方式" prop="telephone">
            <el-input
              placeholder="请输入联系方式"
              v-model="forms.telephone"
              autocomplete="off"
              maxlength="20"
              required
            />
          </el-form-item>
        </el-form>
        <el-form
          v-if="Submitted == 0"
          :rules="rules1"
          ref="formss"
          :model="formss"
        >
          <el-form-item label="类型" prop="resource">
            <el-radio-group style="display: block;height: 5rem;line-height: 5rem;" v-model="formss.resource">
              <el-radio label="个人"></el-radio>
              <el-radio label="企业"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-form
          v-if="Enterprise && Submitted == 0"
          :rules="rules2"
          ref="formsss"
          label-position="top"
          :model="formsss"
          style="display: inline-block"
          hide-required-asterisk
        >
          <el-form-item label="企业名称" prop="company">
            <el-input
              v-model="formsss.company"
              placeholder="请输入企业名称"
              autocomplete="off"
              maxlength="30"
              required
            />
          </el-form-item>
        </el-form>
        <el-form
          v-if="Submitted==1"
          :rules="rules"
          ref="form"
          label-position="top"
          :model="form"
          hide-required-asterisk
        >
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model.number="form.phone"
              placeholder="请输入手机号码"
              autocomplete="off"
              maxlength="11"
            />
          </el-form-item>
          <el-form-item label="图形验证码" prop="imgcode">
            <el-input
              style="width: 170px"
              placeholder="请输入验证码"
              v-model="form.imgcode"
              @input="getImgcode"
              autocomplete="off"
              maxlength="4"
            />
            <div class="get-code" @click="refreshCode()">
              <s-identify
                :contentWidth="100"
                :identifyCode="identifyCode"
              ></s-identify>
            </div>
          </el-form-item>
          <el-form-item label="短信验证码" prop="smsCode">
            <el-input
              style="width: 170px"
              placeholder="请输入短信验证码"
              v-model="form.smsCode"
              autocomplete="off"
              maxlength="6"
            />
            <div
              :class="getcode ? 'get_code' : 'get_code1'"
              @click="sendCode()"
              v-if="!click"
            >
              {{ text }}
            </div>
            <div class="get_code1" v-if="click">{{ text }}</div>
          </el-form-item>
        </el-form>

        <div v-if="Submitted == 2" style="text-align: center">
          <i class="el-icon-success"></i>
          <span style="color: #333; font-size: 2rem; margin-top: 1.25rem;display: block;"
            >信息已提交，我们将在5个工作日内与您联系</span
          >
        </div>
        <div
          v-if="Submitted == 2"
          class="Trial_pop-up_window_1_btn"
          style="background: #ff383b"
          @click="gohome"
        >
          返回首页
        </div>
        <div
          v-if="Submitted==1"
          class="Trial_pop-up_window_1_btn"
          :style="Application ? 'background: #ff383b;' : ''"
          @click="submitForm('form')"
        >
          申请试用
        </div>
        <div
          v-if="Submitted==0"
          class="Trial_pop-up_window_1_btn"
          :style="Application ? 'background: #ff383b;' : ''"
          @click="confirm('forms', 'formsss')"
        >
          申请试用
        </div>

        <div
          @click="dialogFormVisible = false"
          style="
            position: absolute;
            top: 60px;
            right: 0px;
            width: 40px;
            height: 40px;
          "
        >
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- pc端 -->
    <div class="ending" v-if="!MobileTerminal">
      <div class="ending_">
        <div class="ending_left">
          <span class="span1">联系我们</span
          ><span class="span2"
            ><img src="./assets/image/Telephone.png" alt="" />
            电话：0571-87759740</span
          >
          <ul>
            <li style="margin-top: 1.5625rem">
              <img class="img1" src="./assets/image/Zip-code.png" alt="" />
              邮编：31000
            </li>
            <li style="margin-top: 1.1875rem">
              <img class="img2" src="./assets/image/Address.png" alt="" />
              地址：浙江省杭州市余杭区起梦科创A1栋14楼
            </li>
          </ul>
        </div>
        <div class="ending_right">
          <ul>
            <li>
              <img src="./assets/images/Authorization.png" alt="" />
            </li>
            <li>
              <div>扫右侧二维码立即下载</div>
              <img src="./assets/image/left_jiantou.png" alt="" />
            </li>
          </ul>
          <div class="QR_code">
            <img src="./assets/images/hylappios156.png" alt="" />
            扫描下载货易链APP
          </div>
        </div>
      </div>
      <span class="put_on_record"
        >Copyright © 2021 杭州翘歌网络科技有限公司 版权所有 | 备案证书号：
        <a
          style="color: #999999"
          href="https://beian.miit.gov.cn"
          target="_blank"
          >{{ ICPStr }}</a
        ></span
      >
    </div>
    <!-- 移动端 -->
    <div class="ending" v-if="MobileTerminal">
      <div class="ending_" style="width:100%;justify-content:center;margin-top: 0;">
        <div class="ending_left" style="text-align: center;">
          <span class="span2" style="font-size:2.4rem;"
            ><img src="./assets/image/Telephone.png" alt="" />
            电话：0571-87759740</span
          >
          <ul>
            <li style="margin-top:2rem">
              邮编：31000
            </li>
            <li style="margin-top: 1.2rem">
              地址：浙江省杭州市余杭区古墩路1899号起梦科创园A1幢14楼
            </li>
          </ul>
        </div>
      </div>
      <!-- <span class="put_on_record"
        >Copyright © 2021 杭州翘歌网络科技有限公司 版权所有 | 备案证书号：
        <a
          style="color: #999999"
          href="https://beian.miit.gov.cn"
          target="_blank"
          >{{ ICPStr }}</a
        ></span
      > -->
    </div>
  </div>
</template>
<script>
import SIdentify from './components/Sldentify.vue'
import { JSEncrypt } from 'jsencrypt'
import qs from 'qs'

export default {
  components: {
    SIdentify
  },
  name: 'Home',
  data () {
    var phone = (rule, value, callback) => {
      var Telephone = /^1[0-9]{10}$/ /// ^1[34578]\d{9}$/;
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (!Telephone.exec(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    var imgcode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入图形验证码'))
      } else if (value.toLowerCase() !== this.identifyCode.toLowerCase()) {
        callback(new Error('请输入正确的图形验证码'))
      } else {
        callback()
      }
    }
    var smsCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入正确的短信验证码'))
      } else {
        callback()
      }
    }
    var name = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系人'))
      } else {
        callback()
      }
    }
    var telephone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系方式'))
      } else {
        callback()
      }
    }
    var company = (rule, value, callback) => {
      if (value === '' && this.Enterprise) {
        callback(new Error('请输入企业名称'))
      } else {
        callback()
      }
    }
    return {
      Submitted: 0,
      scrollTop: '',
      identifyCode: '',
      identifyCodes: '123456789abcdwerwshdjeJKDHRJHKNPLMKQ',
      activeName: 'homePage',
      dialogFormVisible: false,
      text: '获取验证码',
      click: false,
      Tops: {
        top: ''
      },
      getcode: false,
      Application: false,
      Enterprise: false,
      ICPStr: '',
      formsss: {
        company: ''
      },
      formss: {
        resource: '个人'
      },
      forms: {
        name: '',
        telephone: ''
      },
      form: {
        phone: '',
        imgcode: '',
        smsCode: ''
      },
      rules: {
        phone: [
          {
            validator: phone,
            trigger: 'blur'
          }
        ],
        imgcode: [
          {
            validator: imgcode,
            trigger: 'blur'
          }
        ],
        smsCode: [
          {
            validator: smsCode,
            trigger: 'blur'
          }
        ]
      },
      rules1: {
        telephone: [
          {
            validator: telephone,
            trigger: 'blur'
          }
        ],
        name: [
          {
            validator: name,
            trigger: 'blur'
          }
        ]
      },
      rules2: {
        company: [
          {
            validator: company,
            trigger: 'blur'
          }
        ]
      },
      mKeyboard: null,
      putAway: 1
    }
  },
  watch: {
    form: {
      handler: function (val) {
        if (
          val.phone.length === 11 &&
          val.imgcode.length === 4 &&
          val.smsCode.length === 6
        ) {
          this.Application = true
        }
      },
      deep: true
    },
    forms: {
      handler: function (val) {
        if (val.name && val.telephone.length === 11) {
          this.Application = true
        }
      },
      deep: true
    },
    formss: {
      handler: function (val) {
        if (val.resource === '企业') {
          this.Enterprise = true
        } else {
          this.Enterprise = false
        }
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    var geturl = window.location.host
    var ICPStr = '浙ICP备19019711号-4'
    if (geturl === 'www.huoyl.cn') {
      ICPStr = '浙ICP备19019711号-2'
    } else if (geturl === 'www.huoyl.net') {
      ICPStr = '浙ICP备19019711号-3'
    }
    this.ICPStr = ICPStr
    this.refreshCode()
  },
  methods: {
    goLogin () {
      window.open('https://admin.huoyl.cn/', '_blank')
    },
    Change (fal) {
      if (fal === '1') {
        this.dialogFormVisible = true
        this.Submitted = 2
      }
    },
    confirm (forms, formsss) {
      if (!this.Enterprise) {
        this.$refs[forms].validate((valid) => {
          if (valid) {
            console.log(valid)
            this.Submitted = 1
          } else {
            return false
          }
        })
      } else {
        this.$refs[forms].validate((valid) => {
          if (valid) {
            this.$refs[formsss].validate((valid1) => {
              if (valid1) {
                this.Submitted = 1
              } else {
                return false
              }
            })
          } else {
            return false
          }
        })
      }
    },
    gohome () {
      this.dialogFormVisible = false
      this.Submitted = 0
      this.$router.push({
        name: 'homePage'
      })
      document.documentElement.scrollTop = 0
    },
    // 回到顶部
    toTop () {
      document.documentElement.scrollTop = 0
    },
    // 弹框跟随移动
    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      this.scrollTop = scrollTop
      this.Tops.top = scrollTop + 7.5 + 'rem'
    },
    getImgcode (e) {
      if (e.length === 4) {
        if (this.identifyCode.toLowerCase() === e.toLowerCase()) {
          this.getcode = true
        }
      } else {
        this.getcode = false
      }
    },
    // 头部tad切换
    handleClick (event) {
      this.$router.push({
        name: event.name
      })
    },
    // 发送验证码倒计时
    sendCode () {
      if (!this.form.phone) {
        this.$message('请输入手机号！')
        return
      }
      if (!this.form.imgcode) {
        this.$message('请输入图形验证码！')
        return
      }
      var Telephone = /^1[0-9]{10}$//// ^1[34578]\d{9}$/;
      if (!Telephone.test(this.form.phone)) {
        this.$message('请输入有效手机号码！')
        return
      }
      if (!this.click) {
        this.click = true
        // 倒计时
        this.countdown()
        this.sendSms()
      }
    },
    countdown () {
      var that = this
      var time = 60
      this.text = time + '秒后重新发送'
      // that.click = true;
      var timer = setInterval(function () {
        time--
        if (time <= 0) {
          that.click = false
          that.text = '获取验证码'
          clearInterval(timer)
          return
        }
        that.text = time + '秒后重新发送'
      }, 1000)
    },
    // 发送短信验证码
    sendSms () {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTykrDv1TEKVjDeE29kVLo5M7mctlE65WlHSMN8RVL1iA9jXsF9SMNH1AErs2lqxpv18fd3TOAw0pBaG+cXOxApKdvRDKgxyuHnONOBzxr6EyWOQlRZt94auL1ESVbLdvYa7+cISkVe+MphfQh7uI/64tGQ34aRNmvFKv9PEeBTQIDAQAB'
      )
      var phoneval = this.form.phone
      var sty = phoneval.toString()
      var phonev = encrypt.encrypt(sty)

      const { data: res } = this.$http.post(
        '/common/hylGwSendMsg',
        qs.stringify({
          phone: phonev
        })
      )
      if (res.code === 1) {
      } else {
        this.$message(res.message)
      }
    },
    // 验证短信验证码
    submitForm (form) {
      if (!this.Application) return
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTykrDv1TEKVjDeE29kVLo5M7mctlE65WlHSMN8RVL1iA9jXsF9SMNH1AErs2lqxpv18fd3TOAw0pBaG+cXOxApKdvRDKgxyuHnONOBzxr6EyWOQlRZt94auL1ESVbLdvYa7+cISkVe+MphfQh7uI/64tGQ34aRNmvFKv9PEeBTQIDAQAB'
      )
      var phoneval = this.form.phone
      var phoneStr = phoneval.toString()
      var phonev = encrypt.encrypt(phoneStr)
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/common/hylGwCheckSmsCode',
            qs.stringify({
              phone: phonev,
              smsCode: this.form.smsCode
            })
          )
          if (res.code === 1) {
            // 切换到注册信息页
            this.onSubmit()
          } else {
            this.$message(res.message)
          }
        } else {
          return false
        }
      })
    },
    // 提交表单
    async onSubmit () {
      var formData = new FormData() // 创建form 对象

      var companyName = this.formsss.company ? this.formsss.company : this.forms.name
      var phoneVal = this.form.telephone ? this.form.telephone : this.form.phone
      formData.append('companyName', companyName)
      formData.append('companyAddress', '-')
      formData.append('contactName', this.forms.name)
      formData.append('contactPhone', phoneVal)
      formData.append('phone', this.form.phone)
      formData.append('shortName', companyName)
      formData.append('businessLicense', '')
      formData.append('licenseNoPic', '')
      formData.append('qualification', '-')
      formData.append('legalIdCard', '')
      formData.append('idCardFrontPic', '')
      formData.append('idCardBackPic', '')
      formData.append('idCardPic', '')

      const { data: res } = await this.$http.post(
        '/common/applyHylAccount',
        formData
      )
      if (res.code === 1) {
        this.dialogFormVisible = true
        this.Submitted = 2
      } else {
        this.$message(res.message)
      }
    },
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    randomNum (min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode (data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    }

  }
}
</script>

<style scoped>
.mian {
  width: 100%;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__active-bar {
  top: 0;
  height: 0;
}

/deep/ .el-tabs__item {
  font-size: 1rem;
}

/deep/ .el-tabs__item.is-active {
  color: #e10021;
}

/deep/ .el-tabs__item:hover {
  color: #e10021;
}

/deep/ .el-tabs__nav-wrap::after {
  height: 0;
}

.head {
  width: 100%;
  height: 4.3125rem;
  line-height: 4.3125rem;
  background-color: #FFEBE7;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}

.head1 {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}

.head1 .head_w1 {
  display: flex;
  padding: 0 1.25rem;
  justify-content: space-between;
}

.head_w {
  width: 75rem;
  margin: auto;
  height: 4.3125rem;
  position: relative;
}

.head_left {
  float: left;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.head_left img {
  width: 2.375rem;
  height: 2.375rem;
  margin: auto;
}

.head_l_t {
  line-height: 1.25rem;
  margin-left: 0.5rem;
}

.head_l_t div:nth-child(1) {
  font-size: 1rem;
}

.head_l_t div:nth-child(2) {
  color: #8b8b8b;
}

.head_right {
  float: right;
  display: flex;
  font-size: 1rem;
  position: relative;
}

.head_r_xz {
  height: 4.3125rem;
  line-height: 4.3125rem;
  margin: 0 2.5rem;
}

.head_r_xz:hover {
  color: #e10021;
}

.head_r_xz:hover + .head_r_xz_popup {
  display: block;
}

.head_r_xz_popup {
  display: none;
  /* width: 13.5rem;
  height: 13.5rem; */
  background: #fff;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 1.625rem 1.625rem 0;
  position: absolute;
  top: 4.3125rem;
  right: 20.6rem;
  text-align: center;
}

.head_r_xz_popup img {
  width: 10.25rem;
  /* height: 10.25rem; */
}

.head_r_login {
  height: 4.3125rem;
  line-height: 4.3125rem;
  margin-left: 2.5rem;
}

.head_r_btn {
  /* width: 5rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  line-height: 1.8rem;
  */
  width: 10rem;
height:3.125rem;
border-radius: 1.5625rem;
line-height:3.125rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  color: #fff;
  font-size: 1rem;
  text-align: center;

  background: linear-gradient(90deg, #ff0000 0%, #ff3f6c 100%);
  cursor: pointer;
}

/* 内容 */

.fixed {
  position: fixed;
  right: 2.8125rem;
  bottom: 4.25rem;
  z-index: 2;
}

.fixed_trials span {
  position: absolute;
  top: -1rem;
  left: 3rem;
  font-size: 1.125rem;
  color: #ee0000;
  background-color: #ffdada;
  width: 5.5625rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 1rem;
  transform-origin: 0 50%;
  animation: myfirst 5s ease 1s infinite;
}

@keyframes myfirst {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(-10deg);
  }

  10% {
    transform: rotate(10deg);
  }

  15% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(0deg);
  }
}

/* .fixed img {
  width: 5.25rem;
  height: 5.25rem;
} */
.fixed img {
  width: 15.25rem;
  height: 25.25rem;
}

/* 试用弹窗 */
.Trial_pop-up_window {
  width:68rem;
  height: 38rem;
  background: url(./assets/image/pop-up-bc.png) no-repeat;
  background-size: 100% 100%;
  text-align: left;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 4.375rem 1rem;
  box-sizing: border-box;
  z-index: 5;
}

.Trial_pop-up_window_1 {
  width: 90%;
  background: url(./assets/image/pop-up-bc1.png) no-repeat;
  background-size: 100%;
  border-radius:0.625rem;
  text-align: left;
  position:fixed;
  color: #fff;
  top: 8rem;
  left:5%;
  padding:15rem 5rem 5rem 5rem;
  box-sizing: border-box;
  z-index: 999;
}
.Trial_pop-up_window_1_1 {
  width: 90%;
  background: url(./assets/image/pop-up-bc1.png) no-repeat;
  background-size: 100%;
  border-radius:0.625rem;
  text-align: left;
  position:fixed;
  color: #fff;
  bottom: 4rem;
  left:5%;
  padding:15rem 5rem 5rem 5rem;
  box-sizing: border-box;
  z-index: 999;
}

.Trial_pop-up_window_1 span {
  margin-bottom:1.25rem;
}

.Trial_pop-up_window h2 {
  font-size:2.625rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff2222;
  line-height:4.125rem;
}

.Trial_pop-up_window span {
  display: block;
  font-size: 1.25rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #fdfaf9;
  margin-bottom: 8.25rem;
}

.Trial_pop-up_window_1 .get_code {
  width: 10rem;
  height: 4rem;
  line-height:4rem;
  text-align: center;
  margin-left: 1.25rem;
  background: #ff383b;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
}

.Trial_pop-up_window_1 .get_code1 {
  width: 10rem;
  height: 4rem;
  line-height:4rem;
  text-align: center;
  margin-left: 1.25rem;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  background: #d3d3d3;
  display: inline-block;
}

.Trial_pop-up_window_1_btn {
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  background: #d3d3d3;
  border-radius:2.5rem;
  font-size: 1.5rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 1.125rem;
}

.Trial_pop-up_window .get_code {
  width: 8rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  margin-left: 1.25rem;
  background: #ff383b;
  border-radius: 0.625rem;
  font-size:1.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
}

.Trial_pop-up_window .get_code1 {
  background: #d3d3d3;
  width: 10rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  margin-left: 1.25rem;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
}

.Trial_pop-up_window_btn1 {
  width:9.375rem;
  height:4.625rem;
  display: inline-block;
  background: linear-gradient(0deg, #ff2222 0%, #ff4848 100%);
  border-radius: 0.625rem;
  text-align: center;
  font-size: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 4.625rem;
  margin-top: 2.5rem;
  margin-left: 1.25rem;
}

.Trial_pop-up_window_btn {
  width: 29.125rem;
  height: 4.125rem;
  line-height: 4.125rem;
  text-align: center;
  background: #d3d3d3;
  border-radius: 0.625rem;
  font-size: 1.5rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 1.125rem;
}

/* 底部 */
.ending {
  width: 100%;
  height: 21.25rem;
  background-color: #4d4d4d;
}

.ending_ {
  width: 75rem;

  /* width: 100%; */
  margin: auto;
  margin-top: 6.4375rem;
  padding-top:3.75rem;
  display: flex;
  justify-content: space-between;
}

.ending_left {
  float: left;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.ending_left .span1 {
  font-size:1.25rem;
}

.ending_left .span2 {
  font-size:1.875rem;
  font-weight: bold;
  color: #ff4545;
  margin-left:2.375rem;
  vertical-align: middle;
}

.ending_left .span2 img {
  vertical-align: middle;
  width: 2.25rem;
  height: 2.25rem;
}

.ending_left ul {
  padding: 0;
  font-size: 1rem;
}

.ending_left .img1 {
  vertical-align: middle;
  width:1.1875rem;
  height: 1.0625rem;
}

.ending_left .img2 {
  vertical-align: middle;
  width: 1.125rem;
  height:1.4375rem;
}

.ending_right {
  float: right;
  display: flex;
}

.ending_right ul {
  margin: 4rem 8.4375rem 0 0;
}

.ending_right ul li:nth-child(1) img {
  width: 11.375rem;
  height: 1.4375rem;
}

.ending_right ul li:nth-child(2) {
  display: flex;
  margin-top: 0.625rem;
}

.ending_right ul li:nth-child(2) div {
  width:10.125rem;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
  background: #ff2222;
  border-radius:0.375rem;
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.ending_right ul li:nth-child(2) img {
  width: 2.5rem;
  height:1.5625rem;
  margin-left:0.5rem;
}

.QR_code {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
}

.QR_code img {
  width:8.125rem;
  height: 8.125rem;
  display: block;
}

.put_on_record {
  width: 75rem;
  font-size: 0.875rem;
  display: block;
  margin: 2.5rem auto;
  padding-top: 1.25rem;
  border-top: 1px solid #dfdfdf;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a0a0a0;
}

/* 上传 */
.avatar-uploader {
  width: 6.25rem;
  height: 6.25rem;
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size:1.75rem;
  color: #8c939d;
  width: 6.25rem;
  height: 6.25rem;
  line-height:6.25rem;
  text-align: center;
}

/deep/ .el-input__inner {
  /* width: 510px; */
  border: 1px solid #ff2222;
  font-size: medium;
}
.get-code {
  display: inline-block;
  vertical-align: middle;
}

/deep/ .el-form-item__label {
  font-size:1.5rem;
}
/deep/ .el-radio__label {
  font-size: 1.25rem;
}

/deep/ .el-form-item__label::after {
  content: " *";
  color: red;
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: red;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: red;
  background: red;
}

/deep/ .Trial_pop-up_window_1 .el-form-item__content {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

/deep/ .Trial_pop-up_window_1 .el-form-item__error {
  top: -76%;
  left:5rem;
}

/deep/ .Trial_pop-up_window_1 .el-form-item {
  margin-bottom: 0;
}

/deep/ .Trial_pop-up_window_1 .el-form-item__label {
  padding: 0;
}

.el-icon-circle-close {
  color: red;
  font-size: 2rem;
}

.el-icon-success {
  color: #28c445;
  font-size: 6.5rem;
}

.avatar {
  width: 6.25rem;
  height: 6.25rem;
  display: block;
}
</style>
